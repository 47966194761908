import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import HealthProfessionalBanner from "@/common/components/HealthProfessionalBanner.vue";
import { alertIcon, alertTriangleIcon } from "@debionetwork/ui-icons";
import { isWeb3Injected, web3Enable, web3Accounts, web3FromAddress } from "@polkadot/extension-dapp";
import { queryGetHealthProfessionalAccount } from "@/common/lib/polkadot-provider/query/health-professional";
import localStorage from "@/common/lib/local-storage";
import { myriadContents, myriadContentTotal, myriadTipTotal, myriadCheckUser } from "@/common/lib/api";
import Kilt from "@kiltprotocol/sdk-js";
import CryptoJS from "crypto-js";
import getEnv from "@/common/lib/utils/env";
export default {
  name: "PHDashboard",
  data: () => ({
    account: {},
    alertIcon,
    alertTriangleIcon,
    isNotInstalled: false,
    showConnect: false,
    isConnectToMyriad: false,
    isConnecting: false,
    isConnected: false,
    isVerified: true,
    logo: "debio-logo-loading",
    addressHex: "",
    isLoadingData: false,
    opinionGiven: 0,
    totalIncome: 0,
    myriadAccountDetails: {},
    url: "",
    items: [],
    headers: [{
      text: "User",
      value: "username",
      sortable: true
    }, {
      text: "Category",
      value: "category",
      sortable: true
    }, {
      text: "Opinion Date",
      value: "opinionDate",
      sortable: true
    }, {
      text: "Unlocked Content",
      value: "unlockedContent",
      sortable: true
    }, {
      text: "Opinion Fee",
      value: "opinionFee",
      width: "115",
      sortable: true
    }, {
      text: "Actions",
      value: "action",
      sortable: false,
      align: "center"
    }]
  }),
  components: {
    HealthProfessionalBanner
  },
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      mnemonicData: state => state.substrate.mnemonicData
    })
  },
  watch: {
    async mnemonicData(val) {
      if (val) {
        await this.getInitialData();
      }
    }
  },
  async created() {
    await this.getInitialData();
    await this.checkAccount();
  },
  methods: {
    async checkAccount() {
      const account = await queryGetHealthProfessionalAccount(this.api, this.wallet.address);
      if (!account || (account === null || account === void 0 ? void 0 : account.stakeStatus) !== "Staked") {
        this.$router.push({
          name: "hp-account"
        });
      }
      if (account.verificationStatus === "Unverified") this.isVerified = false;
      this.account = account;
    },
    async toGiveOpinion() {
      this.isNotInstalled = !isWeb3Injected;
      const timelineId = this.account.info.category === "Physical Health" ? getEnv("VUE_APP_PHYSICAL_HEALTH_TIMELINE_ID") : getEnv("VUE_APP_MENTAL_HEALTH_TIMELINE_ID");
      this.url = "".concat(getEnv("VUE_APP_MYRIAD_URL"), "/?type=experience&id=").concat(timelineId);
      if (!this.isNotInstalled) {
        this.showConnect = true;
      }
      // this.$router.push({ name: "hp-dashboard"})
    },

    async checkMyriadAccount() {
      const jwt = await myriadCheckUser(this.addressHex);
      await this.getMyriadTotal(jwt);
      await this.getContent(jwt);
    },
    async toOpinion(post) {
      window.open("".concat(getEnv("VUE_APP_MYRIAD_URL"), "/login?redirect=").concat(getEnv("VUE_APP_MYRIAD_URL"), "%2Fpost%2F").concat(post.postId));
    },
    async getInitialData() {
      const cred = Kilt.Identity.buildFromMnemonic(this.mnemonicData.toString(CryptoJS.enc.Utf8));
      this.addressHex = cred.signPublicKeyAsHex;
      await this.checkMyriadAccount();
    },
    async toInstall() {
      window.open("https://polkadot.js.org/extension/", "_blank");
    },
    async getMyriadTotal(data) {
      const myriadContent = await myriadContentTotal(data.user_id, data.jwt);
      this.opinionGiven = myriadContent.data.count;
      const myriadTip = await myriadTipTotal(data.jwt);
      this.totalIncome = myriadTip.data.data.length;
    },
    async getContent(data) {
      const content = await myriadContents(data.jwt);
      content.data.data.data.map(data => {
        const detail = {
          userId: data.userId,
          postId: data.postId,
          username: data.post.user.username,
          category: data.post.experiences[0].name,
          opinionDate: new Date(data.updatedAt).toLocaleString("en-GB", {
            day: "numeric",
            year: "numeric",
            month: "short"
          }),
          unlockedContent: data.asset.exclusiveContents[0].totalAmount ? "Yes" : "No",
          opinionFee: "".concat(data.asset.exclusiveContents[0].price, " ").concat(data.asset.exclusiveContents[0].symbol)
        };
        this.items.push(detail);
      });
    },
    exportKeystoreAction() {
      try {
        const keystore = localStorage.getKeystore();
        const address = localStorage.getAddress();
        const file = new Blob([keystore], {
          type: "text/json;charset=utf-8"
        });
        const downloadUrl = window.URL.createObjectURL(file);
        const downloadLink = document.createElement("a");
        downloadLink.href = downloadUrl;
        downloadLink.target = "_blank";
        downloadLink.download = "".concat(address, ".json");
        downloadLink.click();
        window.URL.revokeObjectURL(downloadUrl);
      } catch (err) {
        console.error(err);
      }
    },
    async redirectMyriad() {
      const url = this.url;
      window.open(url, "_blank");
    },
    async toContinue() {
      const sender = this.wallet.address;
      const allInjected = await web3Enable("DeBio Network");
      if (!allInjected) return this.isNotInstalled = false;
      const allAccounts = await web3Accounts();
      if (!allAccounts.length) await this.exportKeystoreAction();
      const account = allAccounts.find(account => account.address === sender);
      if (!account) await this.exportKeystoreAction();
      const injector = await web3FromAddress(sender);
      if (injector) {
        this.showConnect = false;
        this.isConnectToMyriad = true;
      }
    }
  }
};